import React, { useCallback, useEffect, useRef, useState } from "react";
import Poppins from "../src/assets/fonts/Poppins/Poppins-Regular.ttf";
import PoppinsBold from "../src/assets/fonts/Poppins/Poppins-ExtraBold.ttf";
import PoppinsLight from "../src/assets/fonts/Poppins/Poppins-Light.ttf";
import RatingScale from "./components/RatingScale";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { SpiderChart } from "./components/SpiderChart"; // Adjust the import path to where your SpiderChart is located
import Swal from "sweetalert2";
import ReportComponent from "./components/ReportComponent";
import VideoComponent from "./components/Video/video";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { createRoot } from "react-dom/client";
import moment from "moment";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from "@mui/material/Select";
import axios from "axios";
import NavButtons from "./components/NavButtons";
import { Swiper as SwiperClass } from "swiper";
import questionsDB from "./questions.json";
import texts from "./texts.json";
import "./App.css";


import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Input,
  InputLabel,

  LinearProgress,

  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
} from "@mui/material";

const idiomas = texts.idiomas;


const introVideo = require("./assets/intro.mp4");
const evoFooterLogo = require("./assets/logo-rodape.png");
const img_intro = require("../src/assets/img_intro.jpg");
const img_final = require("../src/assets/img_final.jpg");

type ReportEntry = {
  soma: number;
  qualificacao: string;
};

// Define the Report type, which represents the structure of the entire report object
type Report = {
  [area: string]: ReportEntry;
};

export default function App() {
  const swiperRef = useRef<SwiperClass>(null);
  const swiperQuizz = useRef<SwiperClass>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answers, setAnswers] = useState<any>({});
  const [chartData, setChartData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [defaultLang, setDefaulLang] = useState<any>("pt");
  const [gift, setGift] = useState("");
  const [showNav, setShowNav] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState<Number>();
  const [fileLink, setFileLink] = useState();
  const [isExploding, setIsExploding] = React.useState(true);
  const [report, setReport] = useState<Report>();
  const { width, height } = useWindowSize();
  const [animationStyle, setAnimationStyle] = useState({});
  const [swiperIndex,setIndex] = useState(0);
  const [formData, setFormData] = useState(null);
  const [nomeEvento, setNomeEvento] = useState("");


  const [formValues, setFormValues] = useState({
    fullName: "" || undefined,
    telephone: "" || undefined,
    email: "" || undefined,
    businessType: "" || undefined,
    ja_e_cliente: undefined,
    pontos_fortes: "" || undefined,
    pontos_de_atencao: "" || undefined,
    desafios: "" || undefined,
  });

   const todosTextos:any =  idiomas.find((obj) => obj.idioma === defaultLang)


  //@ts-ignore
  const handleInputChange = async (areaIndex, perguntaIndex, val) => {
    //@ts-ignore
    const questionId = `${questions.content[areaIndex].area}-${perguntaIndex}`;
    // console.log(questions.content[areaIndex].perguntas[perguntaIndex],"QUESTIONID");
    setAnswers((prevAnswers: any) => {
      const updatedAnswers = { ...prevAnswers, [questionId]: val };
      console.log(updatedAnswers); // Debugging line to inspect updates
      return updatedAnswers;
    });
  };

  const handlePrev = useCallback(async () => {
    if (swiperQuizz && swiperQuizz.current)
      await swiperQuizz.current.slidePrev(1000);
  }, []);

  const handleNext = useCallback(async () => {
    if (swiperQuizz && swiperQuizz.current)
      await swiperQuizz.current.slideNext(1000);
  }, []);

  const handleNextQuizz = useCallback(async () => {
    if (swiperQuizz && swiperQuizz.current)
      await swiperQuizz.current.slideNext(1000);
  }, []);

 
  useEffect(() => {

    if (swiperRef.current) {
      swiperRef.current.on("slideChange", () => {
        if (swiperRef && swiperRef.current)
          setCurrentIndex(swiperRef.current.realIndex);
      });
    }

    // Cleanup the event listener
    return () => {
      if (swiperRef.current) {
        swiperRef.current.off("slideChange");
      }
    };
  }, [swiperRef.current?.realIndex]);

  useEffect(() => {
    pickRandomItem();
    if (swiperRef.current) {
      swiperRef.current.slideTo(currentIndex, 0, false);
    }
  }, [currentIndex, answers]);



  const getForm = (id:string) => {
    const options = {
      method: 'POST',
      url: 'https://scan360-backend.azurewebsites.net/getFields',
      headers: {
        cookie: 'ARRAffinity=d6e7ae766d6a2701cf079eade52fcf281dece87f221d9cfe74e21b7013b05268; ARRAffinitySameSite=d6e7ae766d6a2701cf079eade52fcf281dece87f221d9cfe74e21b7013b05268',
        'Content-Type': 'application/json',
      },
      data: {properties: {formId: id}}
    };

    axios.request(options).then((response) => {
      setFormData(response.data); // Store the response
      return response.data;
    }).catch((error) => {
      console.error(error);
    });
  };

  const setLanguage = (lang: any) => {
    setDefaulLang(lang);
    //@ts-ignore
    const search: any = questionsDB.idiomas.find(
      (item) => item.idioma === lang
    );
    setQuestions(search);
    handleNextQuizz();
  };

  // COMPONENTES CUSTOMIZADOS
  
  const QuizzHeader = styled(Typography)({
  fontSize: "1rem",
  marginBottom: "10px",
  "@media (min-width: 600px)": {
    fontSize: "1.1rem",
  },
  "@media (min-width: 960px)": {
    fontSize: "1.2rem",
  },
  });

 
  const LangBtn = styled(Button)(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: 0,
    fontSize: 42,
    fontWeight: "bold",
    color: "rgba(100, 100, 100, 0.85)",
  }));

  const ResultsBtn = styled(Button)(({ theme }) => ({
    padding: "10px 30px",
    borderRadius: 30,
    fontSize: 15,
    fontWeight: "bolder",
    backgroundColor: "white",
    color: "rgba(100, 100, 100, 1)",
    "&&:hover": {
      // Bottom border on hover
      backgroundColor: "#c7c7c7",
    },
  }));


  const DiagnosticBtn = styled(Button)(({ theme }) => ({
    padding: "5px 20px",
    borderRadius: 30,
    fontSize: 22,
    fontWeight: "bolder",
    backgroundColor: "#8061FF",
    color: "rgba(0, 0, 0, 1)",
    "&:hover": {
      backgroundColor: "#6750A4", // Hover background color
      // Optional: Change the color property if you also want to change the text color on hover
      color: "#FFFFFF", // Text color on hover
    },
  }));

  const buttonSwiper = () => {
    return (
      <NavButtons
        showNav={showNav}
        handlePrev={handlePrev}
        handleNext={handleNext}
        top={30}
      />
    );
  };

  // configs de tema : material-ui

  const theme = createTheme();
  //@ts-ignore
  theme.typography.h1 = {
    fontSize: "2.0rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
  };
  theme.typography.body1 = {
    fontSize: "1.1rem",
    fontFamily: "PoppinsLight",
  };
  theme.typography.body2 = {
    fontSize: "1.3rem",
    fontFamily: "PoppinsLight",
  };

  function checkAllQuestionsAnswered() {
    const allQuestions = questionsDB.idiomas.flatMap((language) => {
      if (language.idioma === defaultLang) {
        return language.content.flatMap((section) =>
          section.perguntas.map((_, index) => `${section.area}-${index}`)
        );
      } else {
        return [];
      }
    });
    return allQuestions.every(
      (questionKey) =>
        answers.hasOwnProperty(questionKey) &&
        answers[questionKey] !== null &&
        answers[questionKey] !== undefined
    );
  }

  function countAnsweredQuestions() {
    const allQuestions = questionsDB.idiomas.flatMap(language => {
      if (language.idioma === defaultLang) {
        return language.content.flatMap(section =>
          section.perguntas.map((_, index) => `${section.area}-${index}`)
        );
      } else {
        return [];
      }
    });
  
    const answeredQuestions = allQuestions.filter(questionKey => 
      answers.hasOwnProperty(questionKey) && 
      answers[questionKey] !== null && 
      answers[questionKey] !== undefined
    );
  
    return answeredQuestions.length;
  }

  function pickRandomItem() {
    const items = todosTextos?.textos.find((obj: { tela: string; }) => obj.tela === "sorteio")
    ?.content
  
    const randomIndex = Math.floor(Math.random() * items.length);
   
    setGift(items[randomIndex]);
    return items[randomIndex];
  }
  type ChartData = {
    subject: string;
    A: number;
    fullMark: number;
  };
  function generateChartData(source: { [key: string]: number }): ChartData[] {
    const capitalize = (s: string) =>
      s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    const categories: { [key: string]: number[] } = {};
    const report = gerarRelatorioPorArea();
    setReport(report);
    Object.entries(source).forEach(([key, value]) => {
      const [category] = key.split("-");
      categories[category] = categories[category] || [];
      categories[category].push(value);
    });
    const chartData: ChartData[] = Object.entries(categories).map(
      ([category, scores]) => {
        const averageScore =
          scores.reduce((sum, score) => sum + score, 0) / scores.length;
        const sum = report[category].soma;

        return {
          subject: `${capitalize(category)} (${sum} pts)`,
          A: averageScore,
          fullMark: 30,
          textos: todosTextos?.textos.filter((obj: { tela: string; },index: number) => { if(obj.tela === "resultado") return obj})[1]
         }
        }
    );
    //@ts-ignore
    setChartData(chartData);
   
    return chartData;
  }

  function handleFormChange(e: any) {
    e.persist();
 
    const { name, value } = e.target as HTMLInputElement; // Safe if only accessing shared properties
    setFormValues((formValues) => ({
      ...formValues,
      [name]: value,
    }));
  }

  async function generatePdfFromHtml(
    pdfPath: string,
    chartDados?: any
  ): Promise<void> {
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });
    const container = document.createElement("div");
    document.body.appendChild(container);
    const root = createRoot(container);
    root.render(<SpiderChart data={chartDados} />);
    await new Promise((resolve) => setTimeout(resolve, 2500));
    const chartCanvas = await html2canvas(container);
    const imgChart = chartCanvas.toDataURL("image/png");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const margin = 20; // Margin from the page edges
    const footerHeight = 30; // Height of the footer area
    const availableHeight = pdfHeight - footerHeight - 2 * margin; // Available height for the chart
    const chartAspectRatio = chartCanvas.width / chartCanvas.height;
    const chartWidth = availableHeight * chartAspectRatio; // Calculate chart width based on its aspect ratio
    const chartXPosition = (pdfWidth - chartWidth) / 2; // Center the chart horizontally
    const chartYPosition = margin; // Position the chart below the top margin

  
    pdf.addImage(
      imgChart,
      "PNG",
      chartXPosition,
      chartYPosition,
      chartWidth,
      availableHeight
    );

    pdf.setFontSize(10);
    const footerYPosition = pdfHeight - footerHeight + 5;
    pdf.text(`${formValues.fullName}`, 10, footerYPosition);
    pdf.text(`Email: ${formValues.email}`, 10, footerYPosition + 10);

    // Add logo to the footer
    const logoWidth = 30;
    const logoHeight = 13;
    pdf.addImage(
      evoFooterLogo,
      "PNG",
      pdfWidth - logoWidth - margin,
      footerYPosition - logoHeight / 2,
      logoWidth,
      logoHeight
    );

    // Draw footer line
    pdf.setDrawColor("#8061FF"); // Line color
    pdf.line(10, footerYPosition - 15, pdfWidth - 10, footerYPosition - 15); // Adjust line length
    root.unmount();
    document.body.removeChild(container);

    const pdfBlob = pdf.output("blob");
    //@ts-ignore
    const name: any = formValues && formValues.fullName ? formValues.fullName.split()[0].toLowerCase()
        : "";
      
    //@ts-ignore
    const pdfFile = new File(
      [pdfBlob],
      `diagnostico-${name}-${nomeEvento}-${moment().format("dd-mm-yy-hh-ss")}.pdf`,
      { type: "application/pdf" }
    );

    const form = new FormData();

    form.append("file", pdfFile);
    form.append("folderPath", "diagnosticos");

    const options = {
      method: "POST",
      url: "https://scan360-backend.azurewebsites.net/uploadGrafico",
      data: form,
    };

    try {
      const response = await axios.request(options);
      console.log(response.data, "[RESULTADO UPLOAD]");
      setLoading(true);

      if (response.data.fileLink) {
        setFileLink(response.data.fileLink);
        await updateUser(userId, response.data.fileLink);

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }

    setTimeout(() => {
      pdf.save(pdfPath);
    }, 1500);
  }

  useEffect(()=>{
    handleStyleAnimation();
  },[swiperQuizz.current?.realIndex])

  const handleStyleAnimation = () => {
    console.log(swiperQuizz.current?.realIndex,"INDEX")
    let style;
      
          style={
            animation: `fadeInSlideUp 1s ease-out`,
          };
  
    return style;
    
  }

  const handleAnimation = () => {
    let style = {};
    if(swiperQuizz.current?.realIndex === 4 || swiperQuizz.current?.realIndex === 5){
      style = {
        animation: `slideUpFadeIn 1s ease-out`,
        opacity: 1
      };
    }

    

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if(entry.isIntersecting){
        // Run the animation function when the component is visible
              setAnimationStyle({animation:'fadeInSlideUp 2s ease-out'});
            }
    });
    }, { threshold: 0.1 }); // Configure the observer to trigger when at least 10% of the element is visible

        if (swiperQuizz.current) {
          observer.observe(swiperQuizz.current);
        }

        // Clean up the observer on component unmount
        return () => {
          if(swiperQuizz.current){
            observer.disconnect();
          }
        };

}

      
  function gerarRelatorioPorArea() {
    // Agrupar e somar as respostas por categoria
    const somasPorCategoria = Object.entries(answers).reduce(
      (acc: any, [key, value]) => {
        const categoria = key.split("-")[0];
        acc[categoria] = (acc[categoria] || 0) + value;
        return acc;
      },
      {}
    );

    const relatorio: any = {};
    Object.entries(somasPorCategoria).forEach(([categoria, soma]) => {
      let qualificacao;
      if (Number(soma) < 10) {
        qualificacao = qualificacao = todosTextos?.textos.filter((obj: { tela: string; },index: number) => { if(obj.tela === "resultado") return obj})[1].content[0].toString();
      } else if (Number(soma) <= 20) {
        qualificacao = todosTextos?.textos.filter((obj: { tela: string; },index: number) => { if(obj.tela === "resultado") return obj})[1].content[1].toString();
      } else {
        qualificacao = todosTextos?.textos.filter((obj: { tela: string; },index: number) => { if(obj.tela === "resultado") return obj})[1].content[2].toString();
      }
      relatorio[categoria] = { soma, qualificacao };
    });

    return relatorio;
  }


 async function gerarRelatorioPorAreaSimples (categ:string){
    const report = gerarRelatorioPorArea();
    const result = Object.keys(report).filter((key) => report[key].qualificacao === categ);
    return result.join(", ");
 
  }

  async function uploadGrafico(uid: any, chartDados: any) {
    try {
      const pdfPath = `dignostico-${moment().format("dd-mm-YYYY-hhss")}.pdf`;
      await generatePdfFromHtml(pdfPath, chartDados);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  async function searchUser() {
    try {
      const options = {
        method: "POST",
        url: "https://scan360-backend.azurewebsites.net/search",
        headers: { "Content-Type": "application/json" },
        data: { email: formValues.email },
      };
      axios
        .request(options)
        .then(function (response) {
          // console.log(response.data.dados.total, "TOTAL");
          if (response.data.dados.total > 0) {
            setUserId(response.data.dados.results[0].id);
            return response.data.dados.results[0].id;
          } else {
            return false;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (e) {}
  }

  async function handleSendUserData() {
    try {
      const user: any = await searchUser();
      if (user) {
        setUserId(user);
        await updateUser(user);
      } else {
        await createUser();
      }
    } catch (e) {
      console.log("ERRO AO PROCURAR USUÁRIO");
    }
  }

  async function createUser() {
    try {
      const options = {
        method: "POST",
        url: "https://scan360-backend.azurewebsites.net/addLeadByForm",
        headers: { "Content-Type": "application/json" },
        data: {
          properties: {
            email: formValues.email,
            firstname: formValues.fullName,
            tipo_de_negocio: formValues.businessType || "",
            phone: formValues.telephone,
            grafico_quizz: "",
            perguntas_quizz: "",
            ja_e_cliente: formValues.ja_e_cliente!==undefined&&formValues.ja_e_cliente ? "Sim" : "Não",
            pontos_fortes: formValues.pontos_fortes || "",
            pontos_de_atencao: "",
            desafios: "",
            lang:defaultLang
          },
        },
      };
      axios
        .request(options)
        .then(function (response) {
          console.log("SALVOU DADOS", response.data);
        })
        .catch(function (error) {
          console.error(error);
        });

        let resp:any;
        if(defaultLang==="pt"){
          await getForm("430f7f70-0edb-4166-9575-b0fdbbcfb4d4");
        }else if(defaultLang==="es"){
          await getForm("430f7f70-0edb-4166-9575-b0fdbbcfb4d4");
        }else if(defaultLang==="en"){
          await getForm("130294a0-3176-4d6b-9f01-100faa5f6095");
        }

        if(formData){
          //@ts-ignore
          setNomeEvento(formData.fieldGroups[9].fields[0].defaultValues[0]);
        }
         
    } catch (e) {
      console.log(e);
    }
  }


  async function updateUser(uid: any, fileUrl?: any) {
    const pontos_de_atencao = await gerarRelatorioPorAreaSimples('PONTOS DE ATENÇÃO');
    const pontos_fortes = await gerarRelatorioPorAreaSimples('PONTOS FORTES');
     console.log(pontos_de_atencao,pontos_fortes,"PONTOS");
    const options = {
      method: "POST",
      url: "https://scan360-backend.azurewebsites.net/updateContactQuizz",
      headers: { "Content-Type": "application/json" },
      data: {
        properties: {
          vid: Number(uid),
          email: formValues.email,
          firstname: formValues.fullName,
          tipo_de_negocio: formValues.businessType || "",
          phone: formValues.telephone,
          grafico_quizz: fileUrl || fileLink,
          ja_e_cliente: formValues.ja_e_cliente ? "Sim" : "Não",
          pontos_de_atencao: pontos_de_atencao,
          pontos_fortes: pontos_fortes,
        },
      },
    };
    axios
      .request(options)
      .then(function (response) {
        console.log("[USUÁRIO ATUALIZADO] ", response);
      })
      .catch(function (error) {
        console.error(error);
      });
  }


   const handleCheckboxChange = (event:any) => {
    setFormValues({
      ...formValues,
      ja_e_cliente: (event.target.name&&event.target.name === "sim")
    });
  };

  const swiperProgress =()=>{
    const progressMap:any = {
      4: 10,
      5: 30,
      6: 50,
      7: 60,
      8: 80,
      9: 100
    };
    // Return the progress for the current index, or 0 if the index is not found
    return progressMap[swiperIndex] || 0;
  }
  const getSwiperSlide =()=>{
    const progressMap:any = {
      4: 1,
      5: 2,
      6: 3,
      7: 4,
      8: 5,
      9: 6
    };
    // Return the progress for the current index, or 0 if the index is not found
    return progressMap[swiperIndex] || 0;
  }

  function getPhoneMask(defaultLang: any) {
    switch (defaultLang) {
      case 'en': // Assuming this is for the USA
        return "(999) 999-9999";
      case 'es': // Assuming a general format for Spanish-speaking countries
        return "+99 999 999 9999";
      case 'pt': // Assuming this is for Brazil
        return "+99 (99) 99999-9999";
      default:
        return "+99 99 99999-9999"; // Default mask
    }
  }
  
  useEffect(() => {
    checkAllQuestionsAnswered();
  }, [answers, handleNext]);

  useEffect(() => {
    const randomItem = pickRandomItem();
    setGift(randomItem);
  }, []);

  return (
    
    <>
      <ThemeProvider theme={theme}>
        <style>
          {`
             .inputStyle {
                marginBottom: 20px;
                backgroundColor: white;
                borderBottom: "2px solid white !important";
                color: "white";
              }
              @font-face {
                font-family: 'Poppins';
                src: url(${Poppins}) format('truetype');
              }
              @font-face {
              font-family: 'PoppinsLight';
              src: url(${PoppinsLight}) format('truetype');
              }

              body {
                font-family: 'Poppins', sans-serif;
              }  
              @font-face {
                font-family: 'PoppinsBold';
                src: local('PoppinsBold'), url(${PoppinsBold}) format('truetype');
              }
            * {
              font-family: 'Poppins', Arial;
              } 
              .Strong {
                color: '#8061FF' !important,
                fontFamily: 'PoppinsBold',
                fontWeight:'bolder'
              }
              .inputForm1{
               
              }
              .wrapNavs{
                position: relative;
                bottom: 10px;
                z-index: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-content: center;
                width: 100%;
              }
              .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1633um2-MuiPaper-root {
                  padding: 0;
              }
              .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-7oz6rj-MuiPaper-root {
                  margin: 0;
                  padding: 0;
              }
              body,html{
                background-color:"white";
              }
            `}
        </style>

        {/* TELA INICIAL */}
        <Box
          flex={1}
          style={{
            height:window.innerHeight,
            color:'white'
          }}
        >

          {swiperQuizz.current&&swiperQuizz?.current?.activeIndex > 3 && swiperQuizz?.current?.activeIndex <= 9 ?
          <>
            <Container style={{backgroundColor:"white", border:0, padding:15, display:"flex", flexDirection:"column"}}>
              <Grid container>
                <Grid item xs={11} paddingTop={1.2} paddingRight={1.2}>
                <LinearProgress  sx={{
                    backgroundColor:"#8061ff40"
                 ,
                  '& .MuiLinearProgress-barColorPrimary': {
                    backgroundColor: '#8061ff', // Custom progress bar color
                  },
                }}
                variant="determinate"  value={swiperProgress()||0} />
                </Grid>
                <Grid item xs={1} textAlign={'center'} padding={0}>
                  <Typography variant="body1" color="black" fontWeight={'bold'}>
                  {getSwiperSlide()+"/6"}
                  </Typography>
                
                </Grid>
              </Grid>
              
            </Container>
          </>:null}

          <Swiper
            style={{height:'inherit'}}
            simulateTouch={false}
            spaceBetween={0}
            slidesPerView={1}
            onSlideChange={async () => {

            const contagemResp = countAnsweredQuestions();
             setIndex(swiperQuizz.current?.activeIndex);
              //@ts-ignore
              if (swiperQuizz.current?.activeIndex === 4) {
                if (
                  !formValues.email ||
                  !formValues.telephone ||
                  !formValues.fullName
                ) {
                  handlePrev();
                  return Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Preencha todos os campos para continuar.",
                  });
                } else {
                  handleSendUserData();
                }
              }

              const fireError = ()=>{
               return Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Preencha todos os campos para continuar.",
                });
              }
            
              if(swiperQuizz.current?.activeIndex===5){
                if(contagemResp<3){
                  handlePrev();
                  fireError();
                }
              }else if(swiperQuizz.current?.activeIndex===6){
                if(contagemResp<6){
                  handlePrev();
                  fireError();
                }
              }else if(swiperQuizz.current?.activeIndex===7){
                if(contagemResp<9){
                  handlePrev();
                  fireError();
                }
              }else if(swiperQuizz.current?.activeIndex===8){
                if(contagemResp<12){
                  handlePrev();
                  fireError();
                }
              }else if(swiperQuizz.current?.activeIndex===9){
                if(contagemResp<15){
                  handlePrev();
                  fireError();
                }
              }else if (swiperQuizz.current?.realIndex === 10) {
                const check = await checkAllQuestionsAnswered();
                if (!check) {
                  handlePrev();
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Responda todas as perguntas para continuar.",
                  });
                } else {
                  setTimeout(() => {
                    handleNext();
                    searchUser();
                  }, 5000);
                }
              }
              // console.log(swiperQuizz.current?.realIndex);
              if (swiperQuizz.current?.realIndex === 11) {
                setIsExploding(true);
                setTimeout(() => {
                  setIsExploding(false);
                }, 3000);
              }

              if (swiperQuizz.current?.realIndex === 2) {
                setShowNav(true);
              }
              if (
                swiperQuizz.current?.realIndex === 0 ||
                swiperQuizz.current?.realIndex === 1
              ) {
                setShowNav(false);
              }
            }}
            onSwiper={(swiper: any) => {
              //@ts-ignore
              swiperQuizz.current = swiper;
            }}
          >
            <SwiperSlide
              style={{ backgroundColor: "#000" }}
              onClick={() => handleNext()}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                  overflow: "hidden", // Prevent the video from overflowing the div's bounds
                }}
              >
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="swiper-background-video"
                  style={{
                    height: window.innerHeight, // Ensure the video's height does not exceed the viewport's height
                    maxWidth: "100%", // Ensure the video's width does not exceed the div's width
                    objectFit: "contain", // Preserve the video's aspect ratio
                  }}
                >
                  <source src={introVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </SwiperSlide>

            {/* SELECIONA O IDIOMA ####################################  */}

            <SwiperSlide
              style={{ backgroundColor: "#000", height: "auto", overflow:'hidden', display:"flex", alignItems:"center",justifyContent:"center" }}
            >
              <Box display={"flex"} gap={3} flexDirection={"column"}>
                <LangBtn
                  onClick={() => {
                    setLanguage("pt");
                  }}
                >
                  Português
                </LangBtn>
                <LangBtn
                  onClick={() => {
                    setLanguage("es");
                  }}
                >
                  Español
                </LangBtn>
                <LangBtn
                  onClick={() => {
                    setLanguage("en");
                  }}
                >
                  English
                </LangBtn>
              </Box>
            </SwiperSlide>

            {/* INTRODUÇÃO ####################################  */}
            <SwiperSlide
              style={{paddingTop:10, backgroundColor: "#000",display:"flex",justifyContent:"center",alignItems:"flex-start", height:'inherit' }}
            >
              <Container>
                <Box padding={1} maxWidth={1000} margin={"auto"}>

                  {swiperQuizz.current?.activeIndex===2 &&
                  <>
                      <img src={img_intro} style={{
                      width:'90%',
                      display:"flex",
                      margin:"0 auto",
                      marginBottom:15,
                      animation: 'fadeInSlideUp 2s ease-in-out'
                      }} />
                  
                  <Box
                      sx={{
                        display: 'flex',
                        gap:2,
                        flexDirection: {
                          xs: 'column', // Note the corrected spelling here
                          md: 'row'
                        },
                      }}
                    >
                  <Typography
                        component={"h2"}
                        style={{  animation: 'fadeInSlideUp 2s ease-out',fontSize:'54px', color: "white",textAlign:"left" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            "" +
                            todosTextos?.textos.find((obj: { tela: string; }, key:number) => key===0&&obj.tela === "bemvindo")
                              ?.content.replace(/\n/g, "<br>"),
                        }}
                      />
                  <Typography
                        component={"h1"}
                        style={{animation: 'fadeInSlideUp 2s ease-out', color: "white",textAlign:"left" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            "" +
                            todosTextos?.textos.find((obj: { tela: string; }, key:number) => key===1&&obj.tela === "bemvindo")
                              ?.content.replace(/\n/g, "<br/>"),
                        }}
                      />
                  </Box>
                    
                  </>}
                  
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={50}
                >
                  {buttonSwiper()}
                </Box>
              </Container>
            </SwiperSlide>

            {/* FORMULARIO COM DADOS DO LEAD */}
            {/* /////////////////////////////////////////////////////////////////////////////////////////// */}
            <SwiperSlide
            className={'swiperSlideCenter'}
              style={{backgroundColor: "#000",display:"flex",justifyContent:"center", height:'auto',paddingTop:'7%' }}
            > 
           
              <Container maxWidth="sm">
                      <Box>
                        <Typography variant="body1">
                        {   todosTextos?.textos.find((obj: { tela: string; }) => obj.tela === "cadastro")?.content}
                        </Typography>
                      </Box>
                      <br></br>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>

                          <InputLabel style={{color:"white", textAlign:"left"}} variant="standard">
                            { todosTextos?.textos.find((obj: { tela: string; },index: number) => obj.tela === "cadastro" && index===3)?.content}
                          </InputLabel>
                          <Input
                            name="fullName"
                            defaultValue={formValues.fullName}
                            onBlur={handleFormChange}
                            style={{width:"100%",padding:10,borderBottom:'1px solid white',color:"white"}}
                            autoComplete="false"
                          />
                          
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <InputLabel style={{color:"white", textAlign:"left"}} variant="standard">
                          { todosTextos?.textos.find((obj: { tela: string; },index: number) => obj.tela === "cadastro" && index===5)?.content} 
                          </InputLabel>
                          <p style={{color:"white",padding:0}}>ex: +55 11 99999-6666</p>
                          <div>  
                          <Input
                            name="telephone"
                            defaultValue={formValues.telephone}
                            onBlur={handleFormChange}
                            autoComplete="false"
                            style={{border: 0,backgroundColor:"transparent",width:"100%",padding:10,marginBottom:10, borderBottom:'1px solid white',color:"white"}}/>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <InputLabel style={{color:"white", textAlign:"left"}} variant="standard">
                          { todosTextos?.textos.find((obj: { tela: string; },index: number) => obj.tela === "cadastro" && index===4)?.content}
                          </InputLabel>
                          <div>
                          <Input                                         
                            name="email"
                            defaultValue={formValues.email}
                            autoComplete="false"
                            onBlur={(e) => handleFormChange(e)}
                            style={{width:"100%",padding:10, backgroundColor:'transparent', marginBottom: "10px", borderBottom:'1px solid white',color:"white" }}
                            />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <>
                            <FormControl component="fieldset" fullWidth variant="standard" style={{ marginBottom: "10px", minWidth: 250 }}>
                              <div style={{ display: "flex", justifyContent: "center" }}>
                                <br></br>
                                  <FormLabel component="legend" style={{ color: "white", textAlign: 'left', fontWeight: 600 }}>
                                  { todosTextos?.textos.find((obj: { tela: string; },index: number) => obj.tela === "cadastro" && index===6)?.content}
                                  </FormLabel>
                              </div>
                              <FormGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 35, color: 'white' }}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={formValues.ja_e_cliente === true}
                                      onChange={handleCheckboxChange}
                                      name="sim"
                                      style={{color:"white"}}
                                    />
                                  }
                                  label={todosTextos?.textos.find((obj: { tela: string; },index: number) => obj.tela === "cadastro" && index===7)?.content}
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={formValues.ja_e_cliente === false}
                                      onChange={handleCheckboxChange}
                                      name="nao"
                                      style={{color:"white"}}
                                    />
                                  }
                                  label={todosTextos?.textos.find((obj: { tela: string; },index: number) => obj.tela === "cadastro" && index===8)?.content}
                                />
                              </FormGroup>
                            </FormControl>
                            </>
                        </Grid>
                      
                      </Grid>
                      {buttonSwiper()}
                    </Container>
                   
            </SwiperSlide>


            {/* PERGUNTAS - ETAPA 1 */}
            <SwiperSlide
              style={{display: 'flex',flexDirection: 'column',backgroundColor: "#FFF", height: "auto" }}>
      
                <Container style={{animation:'fadeInSlideUp 2s ease-out', display:'flex', height:'1000px',flexDirection:'column', justifyContent:'center'}}>
           
                <QuizzHeader paddingX={3} variant={"h5"} color={"black"}>  
                {todosTextos?.textos.find((obj: { tela: string; }) => obj.tela === "introQuizz")?.content}
                </QuizzHeader>
                <br></br>
                {
                  //@ts-ignore
                  questions && Object.assign([], questions).content && Object.assign([], questions).content.map(
                      (obj: any, areaKey: any) => {
                        if (
                          obj.area === "Cultura e Liderança" ||
                          obj.area === "Cultura y Liderazgo" ||
                          obj.area === "Culture and Leadership"
                        ) {
                          return obj.perguntas.map((item: any, index: any) => {
                            return (
                              <RatingScale
                                question={item}
                                 onRatingSelect={(val: any) =>
                                  handleInputChange(areaKey, index, val)
                                }
                              />
                            );
                          });
                        }
                      }
                    )
                }
                {buttonSwiper()}
              </Container>
            
            </SwiperSlide>

 
            {/* PERGUNTAS - ETAPA 2 */}
          
            <SwiperSlide
              style={{display: 'flex',flexDirection: 'column',backgroundColor: "#FFF", height: "auto" }}>
      
                <Container style={{animation:'fadeInSlideUp 2s ease-out', display:'flex', height:'1000px',flexDirection:'column', justifyContent:'center'}}>
               {
                      //@ts-ignore
                      questions && Object.assign([], questions).content && Object.assign([], questions).content.map(
                          (obj: any, areaKey: any) => {
                            if (
                              obj.area === "Experiência do cliente" ||
                              obj.area === "Experiencia del Cliente" ||
                              obj.area === "Customer Experience"
                            ) {
                              return obj.perguntas.map((item: any, index: any) => {
                                return (
                                  <RatingScale
                                    question={item}
                                    onRatingSelect={(val: any) =>
                                      handleInputChange(areaKey, index, val)
                                    }
                                  />
                                );
                              });
                            }
                          }
                        )
                    }
                {buttonSwiper()}
               </Container>
             
            </SwiperSlide>

            {/* PERGUNTAS - ETAPA 3 */}
            <SwiperSlide
              style={{display: 'flex',flexDirection: 'column',backgroundColor: "#FFF", height: "auto" }}>
      
                <Container style={{animation:'fadeInSlideUp 2s ease-out', display:'flex', height:'1000px',flexDirection:'column', justifyContent:'center'}}>
                {
                    //@ts-ignore
                    questions && Object.assign([], questions).content && Object.assign([], questions).content.map(
                        (obj: any, areaKey: any) => {
                          if (
                            obj.area === "Gestão" ||
                            obj.area === "Gestión" ||
                            obj.area === "Management"
                          ) {
                            return obj.perguntas.map((item: any, index: any) => {
                              return (
                                <RatingScale
                                  question={item}
                                  onRatingSelect={(val: any) =>
                                    handleInputChange(areaKey, index, val)
                                  }
                                />
                              );
                            });
                          }
                        }
                      )
                  }
                  {buttonSwiper()}

              </Container>
      
            </SwiperSlide>

            {/* PERGUNTAS - ETAPA 4 */}
            <SwiperSlide
              style={{display: 'flex',flexDirection: 'column',backgroundColor: "#FFF", height: "auto" }}>
      
                <Container style={{animation:'fadeInSlideUp 2s ease-out', display:'flex', height:'1000px',flexDirection:'column', justifyContent:'center'}}>
                  {
                      //@ts-ignore
                      questions && Object.assign([], questions).content && Object.assign([], questions).content.map(
                          (obj: any, areaKey: any) => {
                            if (
                              obj.area === "Inovação e Tecnologia" ||
                              obj.area === "Innovation and Technology" ||
                              obj.area === "Innovación y Tecnología"
                            ) {
                              return obj.perguntas.map((item: any, index: any) => {
                                return (
                                  <RatingScale
                                    question={item}
                                    onRatingSelect={(val: any) =>
                                      handleInputChange(areaKey, index, val)
                                    }
                                  />
                                );
                              });
                            }
                          }
                        )
                    }
                    {buttonSwiper()}
                  </Container>
              
            </SwiperSlide>

            {/* PERGUNTAS - ETAPA 5 */}
            <SwiperSlide
              style={{display: 'flex',flexDirection: 'column',backgroundColor: "#FFF", height: "auto" }}>
      
                <Container style={{animation:'fadeInSlideUp 2s ease-out', display:'flex', height:'1000px',flexDirection:'column', justifyContent:'center'}}>
                  {
                        //@ts-ignore
                        questions && Object.assign([], questions).content && Object.assign([], questions).content.map(
                            (obj: any, areaKey: any) => {
                              if (obj.area === "Marketing") {
                                return obj.perguntas.map((item: any, index: any) => {
                                  return (
                                    <RatingScale
                                      question={item}
                                      onRatingSelect={(val: any) =>
                                        handleInputChange(areaKey, index, val)
                                      }
                                    />
                                  );
                                });
                              }
                            }
                          )
                      }
                      {buttonSwiper()}
                    </Container>
              
            </SwiperSlide>

            {/* PERGUNTAS - ETAPA 6 */}
            <SwiperSlide
              style={{display: 'flex',flexDirection: 'column',backgroundColor: "#FFF", height: "auto" }}>
      
                <Container style={{animation:'fadeInSlideUp 2s ease-out', display:'flex', height:'1000px',flexDirection:'column', justifyContent:'center'}}>
                 {
                            //@ts-ignore
                            questions &&  Object.assign([], questions).content && Object.assign([], questions).content.map(
                                (obj: any, areaKey: any) => {
                                  if (
                                    obj.area === "Vendas" ||
                                    obj.area === "Sales" ||
                                    obj.area === "Ventas"
                                  ) {
                                    return obj.perguntas.map((item: any, index: any) => {
                                      return (
                                        <RatingScale
                                          question={item}
                                          onRatingSelect={(val: any) =>
                                            handleInputChange(areaKey, index, val)
                                          }
                                        />
                                      );
                                    });
                                  }
                                }
                              )
                          }
                          {buttonSwiper()}
                        </Container>
                 
            </SwiperSlide>

            {/* HORA DO SORTEIO */}

            <SwiperSlide
              style={{ backgroundColor: "#7256fc", minHeight:'100vh', height: "100%",display:"flex", alignItems:"flex-start" }}>        

                    <Container style={{animation:'fadeInSlideUp 2s ease-out', display:'flex',flexDirection:'column', justifyContent:'center', height:'100%', alignItems:'center'}}>
                        <Box
                          style={{
                            maxWidth: "800px",
                            width:'100%',
                            flex: 1,
                            paddingTop:5,                  
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems:"center",
                            textAlign:"center",
                            alignContent:"center",
                            zIndex: 10000,
                          }}
                        >
                          <Box style={{ position: "relative"}}>
                            <Typography variant="h4" style={{zIndex:1000000,paddingTop:130 }}>
                            {todosTextos?.textos.filter((obj: { tela: string; }) => obj.tela === "sorteio")[2]?.content}
                            </Typography>
                          </Box>
                          
                          <Box
                            style={{ position: "relative", top: -30,overflow:"hidden", left: 0,right:0, zIndex: 0 }}
                          >
                            <VideoComponent videoSrc={require("./assets/roleta.mp4")} />    
                            
                          </Box>
                          </Box>
                      </Container>
                
            </SwiperSlide>

            <SwiperSlide
              style={{ backgroundColor: "#7256fc", height: "auto",display:"flex",justifyContent:"center",textAlign:"center" }}>        
              
              <Container style={{display:'flex',flexDirection:'column', justifyContent:'center',width:'100%', height:'100%', alignItems:'center'}}>
                <Typography variant="h1">
                  {defaultLang==="pt"&&"Parabéns, você ganhou"}
                  {defaultLang==="en"&&"Congratulations, you've won"}
                  {defaultLang==="es"&&"FELICIDADES, GANASTE"}                  
                </Typography>
                <br></br>
                <br></br>
                <Typography variant="h3">{gift}</Typography>
                <br></br>
                <br></br>
                <ResultsBtn
                  onClick={async () => {
                    await handleNext();
                    setTimeout(async () => {
                      const chartDados = await generateChartData(answers);
                      await uploadGrafico(userId, chartDados);
                    }, 500);
                  }}
                >
                  {/* VER MEU RESULTADO */}
                 {todosTextos?.textos.find((obj: { tela: string; }) => obj.tela === "verResultado")?.content}
                </ResultsBtn>
              </Container>
              {isExploding && <Confetti width={width} height={height} />}

            </SwiperSlide> 
 
            <SwiperSlide style={{overflow:'auto',justifyItems:'flex-start', height: '100vh',backgroundColor:"#FFF",display:'flex', flexDirection:"column",alignItems:"center", justifyContent:"flex-start" }}>
              <Box padding={3} paddingTop={5}>
                <Container>
                  <QuizzHeader variant="body1" color={"black"}>
                   {todosTextos?.textos.find((obj: { tela: string; }) => obj.tela === "resultado")?.content}
                  </QuizzHeader>
                </Container>
                <Container>
                  <Grid container xs={12}>
                    <Grid item xs={12} sm={8} margin={0}>
                      <SpiderChart data={chartData} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignContent={"center"}
                    >
                      <Box
                        style={{                        
                          color: "black",
                          textAlign: "left",
                          margin:15,
                          wordWrap: "break-word",
                          display: 'flex',
                          flexDirection: "column"
                        }}
                      >
                        <Grid container>
                        
                        <Grid xs={12} sm={6} padding={0}>
                              <ReportComponent report={report} />
                        </Grid>
                        <Grid xs={12} sm={6}>
                       
                            <Typography variant="body1">
                            {todosTextos?.textos.filter((obj: { tela: string; },index: number) => { if(obj.tela === "resultado") return obj})[2].content.toString()}
                            </Typography>
                        
                        </Grid>
                      </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>

                {buttonSwiper()}
              </Box>
            </SwiperSlide>

            <SwiperSlide style={{ backgroundColor: "black",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center", height:'inherit' }}>
            
                <Container style={{display:'flex',flexDirection:'column', justifyContent:'center', height:'100%', textAlign:"center", alignItems:'center'}}>
                  <img
                    style={{ width:'100%', maxWidth:1200, margin: "0 auto" }}
                    src={img_final}
                  />
                  <br></br>
                  <DiagnosticBtn
                    onClick={() => {
                      window.location.reload();
                    }}
                    style={{ color: "white", padding: "10px,25px" }}
                  >
                    ENCERRAR
                  </DiagnosticBtn>
                </Container>
              
            </SwiperSlide>
          </Swiper>
        </Box>
      </ThemeProvider>
    </>
  );
}
