import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

// Register the necessary Chart.js components
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

type SpiderChartProps = {
  data: ChartData[];
};

type ChartData = {
  subject: string;
  A: number;
  fullMark: number;
  textos: any
};

export const SpiderChart: React.FC<SpiderChartProps> = ({ data }) => {
  // Prepare the data in the format expected by Chart.js
 
  const chartData = {
    labels: data.map(d => d.subject),
    datasets: [{
      label:data&&data[0]?data[0].textos.content[3]:'Resultado',
      data: data.map(d => d.A),
      // Define a unique color for each point
      pointBackgroundColor: data.map((_, index) => {
        // Cycle through a predefined array of colors
        const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'];
        return colors[index % colors.length];
      }),
      backgroundColor: 'rgba(136, 132, 216, 0.6)',
      borderColor: 'rgba(136, 132, 216, 1)',
      borderWidth: 1,
    }]
  };

  // Define options for the Radar chart, including font size adjustments
  const options = {
    scales: {
      r: {
        angleLines: {
          display: true
        },
        ticks: {
          display: false, // Hide the scale numbers
          // Font size for the ticks can be adjusted here if needed
        },
        suggestedMin: 0,
        suggestedMax: Math.max(...data.map(d => d.A)) + 5
      }
    },
    plugins: {
      legend: {
        labels: {
          // Adjust font size for legend labels
          font: {
            size: 18 // Example size, adjust as needed
          }
        }
      },
      tooltip: {
        // Adjust font size for tooltips
        bodyFont: {
          size: 18 // Example size, adjust as needed
        }
      }
    },
    elements: {
      line: {
        borderWidth: 1
      },
      point: {
        radius: 5 // Adjust the radius of the point (bullet size)
      }
    },
  };

  return (
    <div style={{margin:"0 auto", width: '100%', maxWidth:"600px", height: 'auto', display: "flex", paddingTop: 20,textAlign:"center",alignItems:"center",alignContent:"center", justifyContent: "center" }}>

          <Radar data={chartData} options={options} />

    </div>
  );
};
