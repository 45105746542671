import React, { useState } from 'react';
import { Typography, ToggleButtonGroup, ToggleButton, Card, Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  '& .MuiToggleButtonGroup-grouped': {
    margin: '0.5rem',
    border: 1,
    '&:not(:first-of-type)': {
      borderRadius: '50%',
    },
    '&:first-of-type': {
      borderRadius: '50%',
    },
    '&.Mui-selected': {
      backgroundColor: '#6200EE',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#3700B3',
      },
    },
    '&:hover': {
      backgroundColor: '#c6c6c6',
    },
  },
   overflow: 'auto', // Enable scrolling
  '&::-webkit-scrollbar': {
    width: '2px',
    height:'3px',// Set the width of the scrollbar
  },
  '&::-webkit-scrollbar-track': {
    background: '#f7f7f7', // Set the background color of the track
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#d7d7d7', // Set the color of the scrollbar thumb
  },
});

//@ts-ignore
const RatingScale = ({ question, onRatingSelect }) => {
    const [selectedValue, setSelectedValue] = useState();

    const handleValueChange = (event: any, newValue: React.SetStateAction<undefined>) => {
        setSelectedValue(newValue);
        onRatingSelect(newValue);
    };

    const ratingButtons = Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
    <ToggleButton style={{width:33,height:33, border:'1px solid #c6c6c6',fontSize:'1rem'}} value={number} aria-label={`rate ${number}`} key={number}>
        {number}
    </ToggleButton>
    ));

  const QuizzText = styled(Typography)({
  fontSize: "0.8rem",
  marginBottom: "10px",
  "@media (min-width: 600px)": {
    fontSize: "0.9rem",
  },
  "@media (min-width: 960px)": {
    fontSize: "1.1rem",
  },
  });

const WrapButtons = styled(Box)({
  display: "grid",
  gridTemplateColumns:"1fr 1fr 1fr 1fr 1fr",
  justifyItems:"center",
  gap: '0 2vh',
  "@media (min-width: 600px)": {
    display: "flex",
  },
  "@media (min-width: 960px)": {
    fontSize: "0.9rem",
  },
  });

  const QuizzContainer = styled(Container)({
    width:"100%",display:'flex',flexDirection:'column', justifyContent:'center', height:'100%', alignItems:'center'
  });

  return (
     <Card elevation={1} style={{
      minHeight:170,
      border:'1px solid #8061FF',
      display: 'flex',
      padding:10,
      flexDirection: 'column',
      justifyContent: 'space-around',
      margin:'5px',
      borderRadius:20}}>
      <QuizzText variant="body1" gutterBottom>
        {question}
      </QuizzText>
       
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>

              <StyledToggleButtonGroup
                    exclusive
                    value={selectedValue}
                    onChange={handleValueChange}
                    aria-label="rating scale"
                >
                  <WrapButtons>
                  {ratingButtons}
                  </WrapButtons>
                    
              </StyledToggleButtonGroup>
              {/* <Box textAlign={'left'}>
                <InfoText fontSize={15}>
                  Concordo Completamente
                </InfoText>    
              </Box> */}
              
          </Box>   
      
    </Card>
  );
};

export default RatingScale;
